<template>
  <a-modal :footer="null" width="1000px" @cancel="handleCreate">
    <template #title>
      <div class="modalTitle" :style="{borderColor: store.state.themeColor}">
        选择教案模板
      </div>
    </template>
    <div class="modal">
      <Template
        @handleCreate="handleCreate"
        @handleEdit="handleCreate"
        :templateContent="templateContent"
        :node="courseNode"
      />
    </div>
  </a-modal>
</template>

<script setup >
import Template from '@/components/lesson-plan-editor/components/Template'
import { useStore } from 'vuex'
import { defineEmits, defineProps, computed } from 'vue'

const emit = defineEmits(['ok'])

const store = useStore()

const props = defineProps({
  node: {
    type: Object,
    default: () => null
  }
})

const courseNode = computed(() => props.node)

const handleCreate = () => {
  emit('ok')
}

</script>

<style lang="less" scoped>
.modalTitle{
  margin-top: 10px;
  border-left: 3px solid;
  padding-left: 20px;
}
</style>

import { getRequest, postRequest, deleteRequest } from '@/utils/http'

export async function createTeachPlanTemplate (params) {
  return postRequest('/official/teach-plan-templates', params)
}

export async function editTeachPlanTemplate (id, params) {
  return postRequest(`/official/teach-plan-templates/${id}`, params)
}

export async function getTeachPlanTemplateList (params) {
  return getRequest('/official/teach-plan-templates', params)
}

export async function getTeachPlanTemplateInfo (id) {
  return getRequest(`/official/teach-plan-templates/${id}`)
}

export async function deleteTeachPlanTemplate (id) {
  return deleteRequest(`/official/teach-plan-templates/${id}`)
}

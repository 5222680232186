<template>
  <div class="templateDiv">
    <!-- <a-space> -->
      <div class="template create" @click="handleCreate" >
          <plus-outlined v-if="courseNode" style="color: rgb(30, 195, 143);"/>
        <plus-outlined v-else/>
          <p class="templateTitle">新建空白教案</p>
      </div>
      <div
        class="template use"
        @click="handleEdit(templateItem)"
        v-for="templateItem in pageState.data"
        :key="templateItem.id"
      >
        <div class="templatePreview" v-html="templateItem.content"></div>
        <p class="templateTitle">{{templateItem.name}}</p>
      </div>
    <!-- </a-space> -->
  </div>
  <div class="pagination">
    <a-pagination
      size="small"
      show-less-items
      @change="getCurrentPageData"
      v-model:current="pageState.pagination.current"
      v-model:page-size="pageState.pagination.pageSize"
      :total="pageState.pagination.total"
      :show-total="total => `共 ${total} 条`"
    />
  </div>
</template>

<script setup >
import { PlusOutlined } from '@ant-design/icons-vue'
import { defineEmits, reactive, onBeforeMount, defineProps, computed } from 'vue'
import { useRouter } from 'vue-router'
import { getTeachPlanTemplateList } from '@/services/teach-plan-template'

const router = useRouter()

const emit = defineEmits(['handleCreate', 'handleEdit'])

// 获取创建课件的节点
const props = defineProps({
  node: {
    type: Object,
    default: () => null
  }
})

const courseNode = computed(() => props.node)

const pageState = reactive({
  data: [],
  name: '',
  pagination: {
    total: 0,
    pageSize: 11,
    current: 1
  }
})

const handleCreate = () => {
  emit('handleCreate')
  if (courseNode.value) {
    router.push(`/resource/mine-coursewares/new/0/plan/0/${courseNode.value.id}`)
  } else {
    router.push('/resource/courseware/lessonPlan/edit/0/0/plan')
  }
}

const handleEdit = (templateItem) => {
  emit('handleEdit')
  if (courseNode.value) {
    router.push(`/resource/mine-coursewares/new/0/plan/${templateItem.id}/${courseNode.value.id}`)
  } else {
    router.push(`/resource/courseware/lessonPlan/edit/0/${templateItem.id}/plan`)
  }
}

const getCurrentPageData = async (page) => {
  pageState.pagination.current = page
  await getList({ page: pageState.pagination.current })
}

const getList = async (params) => {
  params = { page: 1, per_page: 11, ...params }
  const { items, pagination } = await getTeachPlanTemplateList(params)
  pageState.data = items
  const { total, pageSize } = pagination
  pageState.pagination.total = total
  pageState.pagination.pageSize = pageSize
}

onBeforeMount(async () => {
  await getList()
})
</script>

<style scoped lang="less">
/deep/ .ant-pagination {
  text-align: right;
  margin-top: 10px;
}
.templateDiv {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  // overflow: auto;
  height: 55vh;
  .template {
    position: relative;
    width: 23%;
    height: 30%;
    margin: 9px;
    box-shadow: 0 0 2px #949494;
    border-radius: 2px;
    background-color: #f6f6f6;
    cursor: pointer;
    .templateTitle {
      margin: 0 auto;
      width: 150px;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space:nowrap;
    }
  }
  .create {
    text-align: center;
    .anticon-plus {
      font-size: 5em;
      margin-top: calc(40% - 70px);
      color: #45aef0;
      position: relative;
    }
  }
  .use {
    .templatePreview {
      padding: 0 10px;
      height: 82%;
      background-color: #fff;
      overflow: auto;
    }
    .templateTitle {
      line-height: 3.2vh;
    }
  }
}
</style>

<template>
  <div v-show="!pageState.firstLoading">
    <div class="formDiv">
      <a-form layout="inline">
        <a-row style="width: 100%" :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="名称">
              <a-input v-model:value="formState.name" />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="类型">
              <a-select
                v-model:value="formState.type"
                :options="formOpts.type"
                @search="handleTagSearch"
              />
            </a-form-item>
          </a-col>
          <template v-if="pageState.advanced">
            <a-col :md="8" :sm="24">
              <a-form-item label="状态">
                <a-select
                  v-model:value="formState.status"
                  :options="formOpts.status"
                  @search="handleTagSearch"
                />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="标签">
                <a-select
                  v-model:value="formState.tag"
                  mode="multiple"
                  placeholder="可选择标签"
                  style="width: 100%"
                  :options="formOpts.tags"
                  @search="handleTagSearch"
                />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="所属教材章节">
                <a href="#" @click="() => (pageState.selectBookModalVisible = true)">
                  {{ formOpts.bookOpts.showName }}
                </a>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="关联类型">
                <a-select
                  :allowClear="true"
                  v-model:value="formState.is_primary"
                  placeholder="请选择"
                  style="width: 100%"
                  :options="formOpts.is_primary"
                  @search="handleTagSearch"
                />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="制作者">
                <a-input v-model:value="formState.author_name" />
              </a-form-item>
            </a-col>
          </template>
          <a-col :md="(!pageState.advanced && 8) || 24" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="
                (pageState.advanced && {
                  float: 'right',
                  overflow: 'hidden'
                }) ||
                  {}
              "
            >
              <a-button type="primary" @click="getList">查询</a-button>
              <a-button style="margin-left: 8px" @click="handleReset"
                >重置</a-button
              >
              <a
                @click="
                  () => {
                    pageState.advanced = !pageState.advanced;
                  }
                "
                style="margin-left: 8px"
              >
                {{ pageState.advanced ? "收起" : "展开" }}
                <up-outlined v-show="pageState.advanced" />
                <down-outlined v-show="!pageState.advanced" />
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="tableDiv">
      <div class="tableOperator">
        <a-dropdown :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-button type="primary" style="width: 80px">+新建</a-button>
          <template #overlay>
            <a-menu>
              <a-menu-item
                @click="handleCreate"
                v-permission="PermissionMap.COURSEWARE_EDIT"
              >
                <fund-projection-screen-outlined style="color:#0099FF;fontSize: 20px"/>
                新建课件
              </a-menu-item>
              <a-menu-item
                @click="() => (pageState.selectTemplateModalVisible = true)"
                v-permission="PermissionMap.COURSEWARE_EDIT"
              >
                <file-text-two-tone two-tone-color="#00CC33" style="fontSize: 20px"/>
                新建教案
              </a-menu-item>
              <a-menu-item
                @click="handleCreateSpeak"
                v-permission="PermissionMap.COURSEWARE_EDIT"
              >
                <user-outlined style="color:#FFCC00;fontSize: 20px;" />
                新建说课
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <a-button
          type="primary"
          @click="handleCreateFile"
          v-permission="PermissionMap.COURSEWARE_EDIT"
        >
          上传文件
        </a-button>
        <a-button
          v-permission="PermissionMap.COURSEWARE_EDIT"
          @click="handlePublishTest"
        >
          批量设置为测试版
        </a-button>
        <a-button
          v-permission="PermissionMap.COURSEWARE_PUBLISH"
          @click="handlePublish"
        >
          批量设置为正式版
        </a-button>
      </div>
      <table-select-info
        v-if="hasSelected"
        :total="tableState.selectedRowKeys.length || 0"
        @clearSelected="() => (tableState.selectedRowKeys = [])"
      />
      <a-table
        :row-selection="{
          selectedRowKeys: tableState.selectedRowKeys,
          onChange: onSelectChange,
          getCheckboxProps: handleGetCheckBoxProps,
        }"
        :dataSource="tableState.data"
        :columns="tableState.columns"
        rowKey="id"
        :pagination="tableState.pagination"
        :loading="tableState.loading"
        @change="handleTableChange"
        :scroll="{ x: 2200 }"
      >
        <template #action="{ record }">
          <a @click="handleInfo(record)">
            详情
          </a>
          <a-divider type="vertical" />
          <a
            @click="() => handleEdit(record)"
            v-permission="PermissionMap.COURSEWARE_EDIT"
          >
            编辑
          </a>
          <a-divider
            v-permission="PermissionMap.COURSEWARE_EDIT"
            type="vertical"
          />
          <router-link
            v-permission="PermissionMap.COURSEWARE_VERSION"
            :to="`/resource/courseware/${record.id}/version`"
          >
            版本管理
          </router-link>
          <a-divider
            v-permission="PermissionMap.COURSEWARE_VERSION"
            type="vertical"
          />
          <a-popconfirm
            title="确定删除该课件吗?"
            ok-text="删除"
            cancel-text="取消"
            @confirm="handleDelete(record)"
          >
            <a v-permission="PermissionMap.COURSEWARE_EDIT">
              删除
            </a>
          </a-popconfirm>
          <a-divider
            v-permission="PermissionMap.COURSEWARE_EDIT"
            type="vertical"
          />
          <a-dropdown>
            <a @click.prevent v-permission="PermissionMap.COURSEWARE_PUBLISH">
              状态设置
              <DownOutlined />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item
                  v-for="(v, k) in statusMap"
                  :key="k"
                  @click="handleSetStatus(record.id, k)"
                  >{{ v.name }}</a-menu-item
                >
              </a-menu>
            </template>
          </a-dropdown>
        </template>
        <template #tags="{ text }">
          <span v-if="!text.length">--</span>
          <span v-else>
            <a-tooltip
              :title="text.map(tItem => tItem.tag).toString()"
              placement="topLeft"
            >
              <span :key="item.tag" v-for="item in text">
                <a-tag class="tag_item_label">{{ item.tag }}</a-tag>
              </span>
            </a-tooltip>
          </span>
        </template>
        <template #status="{ text }">
          <span>{{ statusMap[text].name }}</span>
        </template>
        <template #share_mode="{ text }">
          <a-tooltip :title="shareModeMap[text].name" placement="topLeft">
            <span>{{ shareModeMap[text].name }}</span>
          </a-tooltip>
        </template>
        <template #type="{ text }">
          <span>{{ coursewareTypeMap[text]?.name }}</span>
        </template>
      </a-table>
    </div>
  </div>
  <a-skeleton v-if="pageState.firstLoading" />
  <select-book-modal
    ref="modalRef"
    :type="pageState.type"
    v-model:visible="pageState.selectBookModalVisible"
    @ok="handleSelectBookSection"
  />
  <select-template-modal
    v-model:visible="pageState.selectTemplateModalVisible"
    @ok="handleSelectTemplateSection"
  />
</template>

<script>
import { reactive, computed, onBeforeMount, onActivated } from 'vue'
import { debounce } from '@/utils/common'
import { useRouter, useRoute } from 'vue-router'
import { PermissionMap } from '@/utils/auth'
import SelectBookModal from '@/views/songs/components/SelectBookModal'
import SelectTemplateModal from '@/views/courseware/components/SelectTemplateModal'
import TableSelectInfo from '@/components/common/TableSelectInfo'
import { getTags } from '@/services/common'
import {
  batchSetCoursewareProduction,
  batchSetCoursewareTest,
  deleteCourseware,
  getCoursewareList,
  setCoursewareStatus,
  getCoursewareDevelopVersion
} from '@/services/courseware'
import { message } from 'ant-design-vue'
import { coursewareTypeMap, statusMap, shareModeMap } from '@/utils/dataMap'
import { DownOutlined, UpOutlined, FileTextTwoTone, UserOutlined, FundProjectionScreenOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'

export default {
  name: 'List',
  components: {
    SelectBookModal,
    SelectTemplateModal,
    TableSelectInfo,
    DownOutlined,
    UpOutlined,
    FileTextTwoTone,
    UserOutlined,
    FundProjectionScreenOutlined
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const pageState = reactive({
      onceReset: false,
      firstLoading: true,
      advanced: false,
      tagParams: {
        pageSize: 50,
        page: 1,
        keyword: ''
      },
      selectBookModalVisible: false,
      selectTemplateModalVisible: false
    })
    const formState = reactive({
      name: '',
      book_section_id: [],
      tag: [],
      status: -1,
      type: -1,
      is_primary: ''
    })
    const formOpts = reactive({
      status: [{ label: '全部', value: -1 }],
      type: [{ label: '全部', value: -1 }],
      bookOptions: [],
      is_primary: [
        { label: '主课件', value: 1 },
        { label: '关联课件', value: 0 }
      ],
      bookOpts: {
        showName: '教材名称-章节目录'
      },
      defaultBookOpts: {
        showName: '教材名称-章节目录'
      }
    })
    const tableState = reactive({
      selectedRowKeys: [],
      data: [],
      columns: [],
      loading: true,
      pagination: {
        showSizeChanger: true,
        current: 1,
        total: 1,
        pageSize: 10,
        showTotal: total => `共${total}条`,
        showQuickJumper: true
      }
    })

    const hasSelected = computed(() => tableState.selectedRowKeys.length > 0)

    tableState.columns = [
      {
        title: '课件名称',
        dataIndex: 'name',
        ellipsis: true
      },
      {
        title: '课件类型',
        dataIndex: 'type',
        ellipsis: true,
        width: 100,
        slots: { customRender: 'type' }
      },
      {
        title: '教材',
        dataIndex: 'book.name',
        ellipsis: true
      },
      {
        title: '教材出版社',
        dataIndex: 'book.press_version.name',
        ellipsis: true
      },
      {
        title: '章节',
        dataIndex: 'section_breadcrumb',
        ellipsis: true
      },
      {
        title: '制作者',
        dataIndex: 'author_name',
        ellipsis: true
      },
      {
        title: '标签',
        dataIndex: 'tags',
        ellipsis: true,
        slots: { customRender: 'tags' }
      },
      {
        title: '状态',
        dataIndex: 'status',
        ellipsis: true,
        width: 100,
        slots: { customRender: 'status' }
      },
      {
        title: '分享模式',
        dataIndex: 'share_mode',
        ellipsis: true,
        width: 100,
        slots: { customRender: 'share_mode' }
      },
      {
        title: '创建时间',
        dataIndex: 'created_at',
        ellipsis: true
      },
      {
        title: '修改时间',
        dataIndex: 'updated_at',
        ellipsis: true
      },
      {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 360,
        slots: { customRender: 'action' }
      }
    ]

    const queryParams = computed(() => {
      const params = { ...formState }
      if (params.book_section_id.length === 0) {
        delete params.book_section_id
      } else if (params.book_section_id.length === 1) {
        params.book_id = params.book_section_id[0]
        delete params.book_section_id
      } else {
        params.book_id = params.book_section_id[0]
        params.book_section_id =
          params.book_section_id[params.book_section_id.length - 1]
      }
      for (const key in params) {
        if (
          params[key] === '' ||
          (['status', 'type', 'is_primary'].includes(key) && params[key] === -1)
        ) {
          delete params[key]
        }
      }
      params.per_page = tableState.pagination.pageSize
      params.page = tableState.pagination.current
      return params
    })

    const handleTagSearch = v => {
      pageState.tagParams.page = 1
      pageState.tagParams.keyword = v
      getTagsOptions()
    }

    const getTagsOptions = debounce(async () => {
      const params = {
        per_page: pageState.tagParams.pageSize,
        current: pageState.tagParams.page,
        keyword: pageState.tagParams.keyword
      }
      formOpts.tags = (await getTags(params)).items.map(i => {
        i.label = i.tag
        i.value = i.tag
        return i
      })
    })

    const getList = debounce(async params => {
      // 临时功能，带参数跳转进来的筛选
      const urlParams = {}
      if (!pageState.onceReset && route.query.book_section_id) {
        urlParams.book_section_id = route.query.book_section_id
        formOpts.bookOpts.showName = route.query.book_name
      }
      tableState.loading = true
      params = {
        ...urlParams,
        ...queryParams.value,
        page: 1,
        ...params,
        show_swf: 1
      }
      const { items, pagination } = await getCoursewareList(params)
      tableState.data = items
      tableState.loading = false
      tableState.pagination = { ...tableState.pagination, ...pagination }
    })

    const handleCreate = () => {
      router.push('/resource/courseware/ppt/edit/0')
    }
    const handleCreateFile = () => {
      router.push('/resource/courseware/file/edit/0')
    }
    const handleCreateSpeak = () => {
      router.push('/resource/courseware/speak/edit/0/speak')
    }
    const handleEdit = async record => {
      if (record.type === 'qgroup') {
        message.error('平台暂不支持题目组编辑')
        return
      }
      try {
        await getCoursewareDevelopVersion(record.id)
        if (record.type === 'ppt') {
          router.push(`/resource/courseware/ppt/edit/${record.id}`)
        } else if (record.type === 'plan') {
          router.push(`/resource/courseware/lessonPlan/edit/${record.id}/0/plan`)
        } else if (record.type === 'speak') {
          router.push(`/resource/courseware/speak/edit/${record.id}/speak`)
        } else {
          router.push(`/resource/courseware/file/edit/${record.id}`)
        }
      } catch (e) {
        console.error(e)
        message.error('请求错误或超时')
      }
    }
    const handleInfo = record => {
      if (record.type === 'ppt') {
        router.push(`/resource/courseware/ppt/detail/${record.id}`)
      } else if (record.type === 'plan') {
        router.push(`/resource/courseware/lessonPlan/detail/${record.id}/0/plan`)
      } else if (record.type === 'speak') {
        router.push(`/resource/courseware/speak/detail/${record.id}/speak`)
      } else if (record.type === 'qgroup') {
        const isFormOfficial = !!record?.extend_data?.official_qgroup_id
        const qGroupId = isFormOfficial ? record?.extend_data?.official_qgroup_id : record?.extend_data?.teacher_qgroup_id
        router.push(`/resource/courseware/qgroup/detail/${qGroupId}?isFromOfficial=${isFormOfficial}&name=${record.name}`)
        // router.push(`/resource/audit-management/official/${record?.extend_data?.teacher_qgroup_id}/preview`)
      } else {
        router.push(`/resource/courseware/file/detail/${record.id}`)
      }
    }
    const handleDelete = async record => {
      await deleteCourseware(record.id)
      message.success('删除成功')
      getList({ page: tableState.pagination.current })
    }

    const onSelectChange = selectedRowKeys => {
      tableState.selectedRowKeys = selectedRowKeys
    }

    const handlePublish = async () => {
      if (!tableState.selectedRowKeys.length) {
        message.warning('请选择课件')
        return
      }
      await batchSetCoursewareProduction({
        courseware_ids: tableState.selectedRowKeys
      })
      message.success('操作成功')
      tableState.selectedRowKeys = []
      getList()
    }

    const handlePublishTest = async () => {
      if (!tableState.selectedRowKeys.length) {
        message.warning('请选择课件')
        return
      }
      await batchSetCoursewareTest({
        courseware_ids: tableState.selectedRowKeys
      })
      message.success('操作成功')
      tableState.selectedRowKeys = []
      getList()
    }

    const handleTableChange = pag => {
      tableState.pagination = pag
      getList({ page: tableState.pagination.current })
    }

    onBeforeMount(async () => {
      store.state.rootEl.onkeydown = e => {
        if (e.code === 'Enter' || e.code === 'enter') {
          getList()
        }
      }

      for (const k in statusMap) {
        formOpts.status.push({ value: k, label: statusMap[k].name })
      }
      for (const k in coursewareTypeMap) {
        formOpts.type.push({ value: k, label: coursewareTypeMap[k].name })
      }
      await Promise.all([getList(), getTagsOptions()])
      pageState.firstLoading = false
    })

    const handleSelectBookSection = value => {
      pageState.selectBookModalVisible = false
      pageState.onceReset = true
      if (value.values.length > 0) {
        formOpts.bookOpts.showName = value.labels.join('-')
        formState.book_section_id = value.values
      } else {
        formOpts.bookOpts.showName = formOpts.defaultBookOpts.showName
      }
      getList()
    }
    const handleSetStatus = async (id, status) => {
      await setCoursewareStatus(id, { status })
      message.success('设置成功')
      getList({ page: tableState.pagination.current })
    }

    const handleReset = () => {
      pageState.onceReset = true
      formState.type = -1
      formState.is_primary = ''
      formState.status = -1
      formState.name = ''
      formState.book_section_id = []
      formState.tag = []
      formState.author_name = ''
      tableState.pagination.current = 1
      formOpts.bookOpts.showName = formOpts.defaultBookOpts.showName
      getTagsOptions()
      getList()
    }

    const handleGetCheckBoxProps = (record) => ({
      // disabled: record.author_type !== 'mohuan',
      name: record.name
    })

    onActivated(() => {
      getList({ page: tableState.pagination.current })
    })

    const handleSelectTemplateSection = () => {
      pageState.selectTemplateModalVisible = false
    }

    return {
      shareModeMap,
      coursewareTypeMap,
      statusMap,
      hasSelected,
      pageState,
      formState,
      PermissionMap,
      formOpts,
      tableState,
      handleSelectBookSection,
      handleSelectTemplateSection,
      handleTagSearch,
      getList,
      handleDelete,
      handleCreate,
      handleEdit,
      onSelectChange,
      handleCreateFile,
      handleCreateSpeak,
      handlePublish,
      handleTableChange,
      handleInfo,
      handleSetStatus,
      handlePublishTest,
      handleReset,
      handleGetCheckBoxProps
    }
  }
}
</script>

<style lang="less" scoped>
.formDiv {
  background-color: white;
  margin: 30px 30px 0 30px;
  padding: 20px 38px;
}
.tableDiv {
  background-color: white;
  margin: 30px 30px 0 30px;
  padding: 20px 38px;
  .iconExtra {
    display: inline-block;
    font-size: 24px;
    margin: 2px 6px 0 0;
  }
  .cardTableDiv {
    padding-left: 20px;
  }
  .pagination {
    margin-top: 20px;
    text-align: right;
  }
}
.ant-col {
  margin-top: 16px;
}
.tableOperator {
  text-align: right;
  margin-bottom: 12px;
  button {
    margin: 0 10px;
  }
}

.tableOperator /deep/ .ant-dropdown-menu {
  width: 150px;
}
.tableOperator /deep/ .ant-dropdown-menu-item {
  line-height: 45px;
  text-align: center;
  font-size: 16px;
}

.tag_item_label {
  max-width: 100px;
  overflow: hidden; //超出一行文字自动隐藏
  text-overflow: ellipsis; //文字隐藏后添加省略号
  white-space: nowrap; //强制不换行
  cursor: default;
}
</style>
